export  const warehoustList= [
    {
        code: "WHSE014344",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 21979129,
        lon: 71162736,
        posTime: 1721119699,
        status: null,
        title: "青州仓(恒晟仓)",
        traffic: "WHSE014344",
        type: "warehouse",
        typeMain: "warehouse",
    },
    {
        code: "WHSE343330",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 21628438,
        lon: 72115887,
        posTime: 1721119699,
        status: null,
        title: "青岛仓（元康）",
        traffic: "WHSE343330",
        type: "warehouse",
        typeMain: "warehouse",
    },
    {
        code: "WHSE312955",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 21619951,
        lon: 72127645,
        posTime: 1721119699,
        status: null,
        title: "青岛仓(恒顺丰)",
        traffic: "WHSE312955",
        type: "warehouse",
        typeMain: "warehouse",
    },
    {//万花路仓 WHSE735923

        code: "WHSE735923",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 13969090,
        lon: 67883500  ,
        posTime: 1721119699,
        status: null,
        title: "万花路仓",
        traffic: "WHSE735923",
        type: "warehouse",
        typeMain: "warehouse",
    },
    {//南海仓  WHSE608876
        code: "WHSE608876",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 13973065,
        lon: 67880500,
        posTime: 1721119699,
        status: null,
        title: "南海仓",
        traffic: "WHSE608876",
        type: "warehouse",
        typeMain: "warehouse",
    },
    {//恒晟 1 号仓 WHSE863947
        code: "WHSE863947",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 13968500,
        lon: 67882000,
        posTime: 1721119699,
        status: null,
        title: "恒晟 1 号仓",
        traffic: "WHSE863947",
        type: "warehouse",
        typeMain: "warehouse",
    },
    {//万顷洋仓 WHSE140711

        code: "WHSE140711",
        nameGoods:"泥炭",
        num1:10200,
        num2:175,
        lat: 13969000,
        lon: 67888000,
        posTime: 1721119699,
        status: null,
        title: "万顷洋仓",
        traffic: "WHSE140711",
        type: "warehouse",
        typeMain: "warehouse",
    },

]